import './App.css';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Skeleton from './components/Skeleton';

function Home() {
  return (
    <div className="App">
      <Skeleton></Skeleton>
    </div>
  );
}

function App() {
  return (
    <Switch>
      <Route path="/landing" component={Home} />
      <Route render={() => <Redirect to="/landing" />} />
    </Switch>
  )
}

export default App;
