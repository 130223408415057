import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// TODO: change back to const.
let theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#cef2f2"
    },
    secondary: {
      main: "#ffab40"
    }
  }
});

// TODO: Set specific font sizes based on user's
//       viewpoint size. See here for examples: https://material-ui.com/customization/typography/#font-size 
theme = responsiveFontSizes(theme);


ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
