import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import first_visit_cutsheet from '../static/images/FIRST_VISIT_CUTSHEET.pdf';
import follow_up_cutsheet from '../static/images/FOLLOW_UP_CUTSHEET.pdf';
import timeline_cutsheet from '../static/images/TIMELINE_CUTSHEET.pdf';
import stuff_ive_tried_cutsheet from '../static/images/STUFF_IVE_TRIED_CUTSHEET.pdf';
import treatment_team_cutsheet from '../static/images/TREATMENT_TEAM_CUTSHEET.pdf';
import risk_factors_cutsheet from '../static/images/RISK_FACTORS_CUTSHEET.pdf';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%'
    },
    hiddenItemOnSmall: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    }
  }),
);

export default function GetStarted() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="get-started">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4">Get Started</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">First visit cutsheet</Typography>
            <div>
              <div>
                <Link href={first_visit_cutsheet} variant="body1">
                  Download blank cutsheet
                </Link>
              </div>
            </div>
            <div>
              Good for:
              <ul>
                <li>Your first time using a cutsheet</li>
                <li>An initial visit with a new doctor</li>
                <li>A visit with a specialist or surgeon</li>
              </ul>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Follow up cutsheet</Typography>
            <div>
              <div>
                <Link href={follow_up_cutsheet} variant="body1">
                  Download blank cutsheet
                </Link>
              </div>
            </div>
            <div>
              Good for:
              <ul>
                <li>If you've used a cutsheet before</li>
                <li>A follow-up with a doctor you know (e.g., Primary 
                    Care Physician, Dentist, etc.)
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Timeline cutsheet</Typography>
            <div>
              <div>
                <Link href={timeline_cutsheet} variant="body1">
                  Download blank cutsheet
                </Link>
              </div>
            </div>
            <div>
              Good for:
              <ul>
                <li>A visit with a doctor about a chronic illness</li>
                <li>If you have a complex medical history</li>
                <li>If there have been many steps in your treatment journey</li>
              </ul>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Stuff I've Tried cutsheet</Typography>
            <div>
              <div>
                <Link href={stuff_ive_tried_cutsheet} variant="body1">
                  Download blank cutsheet
                </Link>
              </div>
            </div>
            <div>
              Good for:
              <ul>
                <li>A visit with a doctor about a chronic illness</li>
                <li>If you've already tried many things to alleviate your
                  illness and want to avoid repeating stuff you've already tried
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Treatment Team cutsheet</Typography>
            <div>
              <div>
                <Link href={treatment_team_cutsheet} variant="body1">
                  Download blank cutsheet
                </Link>
              </div>
            </div>
            <div>
              Good for:
              <ul>
                <li>If you're working with a team of doctors who need
                  to be coordinating
                </li>
                <li>If your doctors do not have access to the same medical 
                  records
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Risk Factors cutsheet</Typography>
            <div>
              <div>
                <Link href={risk_factors_cutsheet} variant="body1">
                  Download blank cutsheet
                </Link>
              </div>
            </div>
            <div>
              Good for:
              <ul>
                <li>If you are at risk for a certain disease (cancer, heart 
                  disease, Alzheimer's, etc.)
                </li>
                <li>Help your doctor make an informed decision about disease
                  screening
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}