import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Landing from './Landing';
import GetStarted from './GetStarted';
import TopQuestions from './TopQuestions';
import Footer from './Footer';
import { HashLink } from 'react-router-hash-link';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  noLinkStyles: {
    textDecoration: 'none',
    color: '#ffffff',
    display: 'flex',
    width: '100%'
  },
  noHomeLinkStyles: {
    textDecoration: 'none',
    color: '#212121',
  }
}));
  
  function Skeleton(props) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button key="Home">
            <HashLink 
              to={'/landing#top'}
              className={classes.noLinkStyles}
            >
              <ListItemIcon>
                <Icon>home</Icon>
              </ListItemIcon>
              <ListItemText primary="Home"></ListItemText>
            </HashLink>
          </ListItem>
          <ListItem button key="Get started">
            <HashLink 
              to={'/landing#get-started'}
              className={classes.noLinkStyles}
            >
              <ListItemIcon>
                <Icon>forward</Icon>
              </ListItemIcon>
              <ListItemText primary="Get started"></ListItemText>
            </HashLink>
          </ListItem>
          <ListItem button key="Top questions">
            <HashLink 
              to={'/landing#top-questions'}
              className={classes.noLinkStyles}
            >
              <ListItemIcon>
                <Icon>question_answer</Icon>
              </ListItemIcon>
              <ListItemText primary="Top questions"></ListItemText>
            </HashLink>
          </ListItem>
          <ListItem button key="Give feedback">
            <HashLink 
              to={'/landing#give-feedback'}
              className={classes.noLinkStyles}
            >
              <ListItemIcon>
                <Icon>feedback</Icon>
              </ListItemIcon>
              <ListItemText primary="Give feedback"></ListItemText>
            </HashLink>
          </ListItem>
        </List>
      </div>
    );
  
    const container = window !== undefined ? () => window().document.body : undefined;
  
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              <HashLink to="/#top" className={classes.noHomeLinkStyles}>Medsheet</HashLink>
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="main navigation">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <Container maxWidth="xl" disableGutters>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Landing></Landing>
              </Grid>
              <Grid item xs={12}>
                <GetStarted></GetStarted>
              </Grid>
              <Grid item xs={12}>
                <TopQuestions></TopQuestions>
              </Grid>
              <Grid item xs={12}>
                <Footer></Footer>
              </Grid>
            </Grid>
          </Container>
        </main>
        </Container>
      </div>
    );
  }
  
  export default Skeleton;