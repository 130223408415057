import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import landing_image from '../static/images/undraw_medicine_b1ol_v2.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    image: {
      [theme.breakpoints.down('sm')]: {
        maxHeight: '40vh',
        maxWidth: '70vw'
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: '30vh',
        maxWidth: '50vw'
      },
    },
    subHeading: {
      marginTop: '10px'
    }
  }),
);

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="landing">
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Typography variant="h3">Always be on the same page with your doctor.</Typography>
            <Typography variant="h6" className={classes.subHeading}>
              Get started by creating a one page overview of 
              your health concerns to bring to your next appointment.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <img src={landing_image} className={classes.image} alt=""></img>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}